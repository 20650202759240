import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  container: (theme) => ({
    margin: "0 -24px",

    [theme.mediaRequests.notMobile]: {
      width: 500,
    },
  }),

  emptyBlock: {
    padding: "30px 15px",
    textAlign: "center",
  },
});
