import { Models } from "@services/api";

export const formatPhone = (phone: Models.Phone): string | null => {
  if (!phone.country || !phone.number) {
    return null;
  }

  switch (phone.country) {
    case Models.Countries.Us:
      return phone.number.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    case Models.Countries.Aus:
      return phone.number.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    default:
      return phone.number;
  }
};

export const getPhoneCountryPrefix = (country: Models.Countries) => {
  switch (country) {
    case Models.Countries.Us:
      return "+1";
    case Models.Countries.Aus:
      return "+61";
    default:
      throw new Error("Unsupported country");
  }
};
