import React, { useState } from "react";
import clsx from "clsx";
import { useHistory, useLocation } from "react-router-dom";
import { DateTime } from "luxon";
import numeral from "numeral";
import qs from "query-string";
import { useQueryClient, useMutation } from "react-query";

import Button from "@ui-kit/Button";
import { createCss } from "./styles";
import Typography from "@ui-kit/Typography";
import { useNotificationStore } from "@store/NotificationStore";
import { Models, API } from "@services/api";
import { must } from "@utils/must";
import { expectServerError } from "@helpers/serverError";
import { formatPhone } from "@helpers/format-phone";
import { Spacer } from "@ui-kit/Spacer";
import { RequestProps } from "./types";
import { QUERIES } from "@constants/queries";
import { FacilityCard } from "@models/FacilityCard";
import { BookingRequest } from "@models/BookingRequest";

export const ReservationRequest: React.FC<RequestProps> = ({ request }) => {
  const [, { setNotification, setUnknownErrorNotification }] =
    useNotificationStore();
  const history = useHistory();
  const { search } = useLocation();
  const [processCancellation, setProcessCancellation] = useState(false);
  const queryClient = useQueryClient();
  const { mutate: cancelReservation, isLoading } = useMutation(
    (id: number) => API.booking.cancel({ id }),
    {
      onSuccess: (data) => {
        queryClient.setQueriesData<BookingRequest[]>(
          QUERIES.USER_BOOKING_REQUEST,
          (prev) => {
            return must(prev).map((r) => (r.id === data.id ? data : r));
          },
        );
        setProcessCancellation(false);
      },
      onError: (e) => {
        const errors = expectServerError(e, setUnknownErrorNotification);

        setNotification({
          message: errors[0].title,
          type: "error",
        });
      },
    },
  );

  const css = createCss();

  function goToFacility(facility: FacilityCard) {
    history.push({
      pathname: `/map/${facility.id}`,
      search: qs.stringify({
        ...qs.parse(search),
        lat: facility.address.location.lat,
        lng: facility.address.location.lon,
      }),
    });
  }

  return (
    <div css={css.root} data-test="reservation-item" data-id={request.id}>
      <div css={css.header}>
        <div css={css.facilityInfo}>
          <Typography
            color="primary"
            bolded
            css={css.facilityName}
            onClick={() => goToFacility(request.facilityCard)}
          >
            {request.facilityCard.name}
          </Typography>
          <Typography paragraph color="textSecondary">
            {request.facilityCard.getFullAddress()}
          </Typography>
        </div>

        {!request.isCancelled() &&
          request.bookingSlot.shift.status !==
            Models.BookingConfigSuggestedShiftStatus.Completed && (
            <div css={css.cancellationSection}>
              {!processCancellation ? (
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  disabled={!request.isCancellable}
                  onClick={() => setProcessCancellation(true)}
                  name="cancellation"
                >
                  Cancel
                </Button>
              ) : (
                <Button
                  color="primary"
                  fullWidth
                  variant="outlined"
                  onClick={() => setProcessCancellation(false)}
                  name="cancellationBack"
                >
                  Back
                </Button>
              )}
            </div>
          )}
        {request.isCancelled() && (
          <div css={css.cancellationSection}>
            <Button
              variant="contained"
              color="error"
              fullWidth
              disabled
              size="small"
            >
              Cancelled
            </Button>
          </div>
        )}
      </div>

      <div css={css.content}>
        {processCancellation && (
          <div css={css.cancellationOverlay}>
            <div>
              <div css={css.errorMessage}>
                Refund due:{" "}
                {numeral(request.bookingSlot.serviceFee / 100).format(
                  "$0,0.00",
                )}
              </div>

              <div className="actions">
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  size="large"
                  onClick={() => cancelReservation(request.id)}
                  name="confirmCancellation"
                  loading={isLoading}
                >
                  Confirm
                </Button>
              </div>
            </div>
          </div>
        )}

        <div
          className={clsx({
            hidden: processCancellation,
          })}
        >
          <Typography>
            <Typography variant="inherit" bolded>
              Service date:
            </Typography>{" "}
            <span css={css.info}>
              {DateTime.fromISO(request.bookingSlot.shift.dateTimeFrom, {
                zone: request.facilityCard.getTimezone(),
              }).toFormat("cccc, MM/dd/yyyy")}
            </span>
          </Typography>

          <Spacer size="xxs" />

          <Typography>
            <Typography variant="inherit" bolded>
              Service time:
            </Typography>{" "}
            <span css={css.info}>
              {`${DateTime.fromISO(request.bookingSlot.shift.dateTimeFrom, {
                zone: request.facilityCard.getTimezone(),
              })
                .toFormat("h:mma")
                .toLowerCase()} ` +
                `- ${DateTime.fromISO(request.bookingSlot.shift.dateTimeTo, {
                  zone: request.facilityCard.getTimezone(),
                })
                  .toFormat("h:mma")
                  .toLowerCase()}`}
            </span>
          </Typography>

          <Spacer size="sm" />

          <Typography>
            <Typography variant="inherit" bolded>
              Reservation ID:
            </Typography>{" "}
            <span css={css.info}>{request.publicId}</span>
          </Typography>

          <Spacer size="xxs" />

          <Typography>
            <Typography variant="inherit" bolded>
              Booking date:
            </Typography>{" "}
            <span css={css.info}>
              {DateTime.fromISO(request.createdAt, {
                zone: request.facilityCard.address.timezone,
              }).toFormat("cccc, MM/dd/yyyy")}{" "}
              {DateTime.fromISO(request.createdAt, {
                zone: request.facilityCard.address.timezone,
              })
                .toFormat("h:mma")
                .toLowerCase()}
            </span>
          </Typography>
          {request.isCancelled() && (
            <>
              <Spacer size="xxs" />
              <Typography>
                <Typography variant="inherit" bolded>
                  Date cancelled:
                </Typography>{" "}
                <span css={css.info}>
                  {DateTime.fromISO(must(request.cancelledAt), {
                    zone: request.facilityCard.address.timezone,
                  }).toFormat("MM/dd/yyyy")}{" "}
                  at{" "}
                  {DateTime.fromISO(must(request.cancelledAt), {
                    zone: request.facilityCard.address.timezone,
                  })
                    .toFormat("h:mma")
                    .toLowerCase()}
                </span>
              </Typography>
            </>
          )}

          <Spacer size="sm" />

          <Typography>
            <Typography variant="inherit" bolded>
              Parent name:
            </Typography>{" "}
            <span
              css={css.info}
            >{`${request.parent?.firstName} ${request.parent?.lastName}`}</span>
          </Typography>

          <Spacer size="xxs" />

          <Typography>
            <Typography variant="inherit" bolded>
              Phone #:
            </Typography>{" "}
            <span css={css.info}>
              {request.parent?.phone
                ? formatPhone(request.parent.phone)
                : "---"}
            </span>
          </Typography>

          <Spacer size="xxs" />

          <Typography>
            <Typography variant="inherit" bolded>
              Email:
            </Typography>{" "}
            <span css={css.info}>{request.parent?.email}</span>
          </Typography>

          <Spacer size="sm" />

          <Typography>
            <Typography variant="inherit" bolded>
              Child name:
            </Typography>{" "}
            <span css={css.info}>{request.child.getFullName()}</span>
          </Typography>

          <Spacer size="xxs" />

          <Typography>
            <Typography variant="inherit" bolded>
              Child age:
            </Typography>{" "}
            <span css={css.info}>{request.child.getAge()}</span>
          </Typography>

          {!!request.medicalInformation && (
            <>
              <Spacer size="xxs" />
              <Typography>
                <Typography variant="inherit" bolded>
                  About:
                </Typography>{" "}
                <span css={css.info}>{request.medicalInformation}</span>
              </Typography>
            </>
          )}

          {!!request.additionalInfo && (
            <>
              <Spacer size="sm" />
              <Typography bolded>Additional information:</Typography>
              <Typography>
                <span css={css.info}>{request.additionalInfo}</span>
              </Typography>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
