import React from "react";
import { useHistory } from "react-router-dom";
import orderBy from "lodash/orderBy";
import { useQuery } from "react-query";
import track from "react-tracking";

import Dialog from "@ui-kit/Dialog";
import { createCss } from "./styles";
import Typography from "@ui-kit/Typography";
import { API } from "@services/api";
import { useUserStore } from "@store/UserStore";
import { ReservationRequest } from "./Request";
import { QUERIES } from "@constants/queries";
import { Guard } from "@components/Guard";
import { useAppStore } from "@store/AppStore";

const ReservationsHistory: React.FC = () => {
  const [{ realLocation }] = useAppStore();
  const [{ user }] = useUserStore();
  const history = useHistory();
  const { data: reservations } = useQuery(
    QUERIES.USER_BOOKING_REQUEST,
    () =>
      API.booking.getList({
        parentAccountId: [user!.account.id],
      }),
    {
      refetchOnMount: "always",
    },
  );

  const css = createCss();

  if (!reservations) {
    return null;
  }

  const onClose = () => {
    history.replace(
      realLocation.current.pathname + realLocation.current.search,
    );
  };

  return (
    <Dialog
      open
      onClose={onClose}
      title={`Reservation history (${reservations.length})`}
      data-test="reservations-list"
    >
      <div css={css.container}>
        {!reservations.length && (
          <div css={css.emptyBlock}>
            <Typography variant="inherit" bolded>
              No reservations as of yet
            </Typography>
          </div>
        )}
        <div>
          {orderBy(
            reservations.slice(),
            ["bookingSlot.shift.dateTimeFrom", "createdAt"],
            ["desc", "desc"],
          ).map((r) => (
            <ReservationRequest request={r} key={r.id} />
          ))}
        </div>
      </div>
    </Dialog>
  );
};

export default track({
  page: "Reservations History",
})(() => (
  <Guard returnUrl="my-reservations">
    <ReservationsHistory />
  </Guard>
));
