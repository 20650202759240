import React from "react";
import qs from "query-string";
import { Redirect } from "react-router-dom";

import { useUserStore } from "@store/UserStore";
import { ROUTES } from "@constants";
import { GuardProps } from "./types";

export const Guard: React.FC<GuardProps> = ({ returnUrl, children }) => {
  const [{ user }] = useUserStore();

  if (!user) {
    const search = qs.stringify({
      returnUrl,
    });

    return (
      <Redirect
        to={{
          pathname: ROUTES.LOGIN_FORM,
          search,
        }}
      />
    );
  }

  return <React.Fragment key={user.id}>{children}</React.Fragment>;
};
