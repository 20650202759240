import React from "react";

interface SpacerProps {
  size?: "xxs" | "xs" | "small" | "sm" | "medium" | "md" | "large";
}

export const Spacer: React.FC<SpacerProps> = ({ size = "small" }) => {
  return (
    <div
      css={{
        height:
          size === "xxs"
            ? 4
            : size === "xs"
            ? 8
            : size === "small" || size === "sm"
            ? 16
            : size === "medium" || size === "md"
            ? 32
            : 48,
      }}
    />
  );
};
