import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  root: {
    padding: 15,

    "&:nth-of-type(odd)": {
      backgroundColor: "rgba(200, 200, 200, .2)",
    },
  },

  header: {
    display: "flex",
  },

  facilityInfo: {
    flex: 1,
  },

  facilityName: {
    cursor: "pointer",
  },

  info: (theme) => ({
    color: theme.palette.text.secondary,

    "& b": {
      color: theme.palette.text.primary,
    },
  }),

  content: {
    position: "relative",

    "& .hidden": {
      visibility: "hidden",
    },
  },

  errorMessage: (theme) => ({
    color: theme.palette.error.main,
    fontWeight: 600,
    textAlign: "center",

    "& ~ $errorMessage": {
      marginTop: 10,
    },
  }),

  cancellationSection: {
    marginLeft: 10,
    width: 80,
  },

  cancellationOverlay: {
    alignItems: "center",
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 10,

    "& .actions": {
      marginTop: 20,
      textAlign: "center",

      "& button": {
        maxWidth: 250,
      },
    },
  },
});
